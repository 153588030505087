<template>
  <div id="app" class="ignore-app">
    <Loader v-show="$store.state.loader"></Loader>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="! $route.meta.keepAlive"/>
  </div>
</template>

<script>
import Loader from './components/Loader'
export default {
  name: 'App',
  components: {
    Loader,
  },
  created () {
    
  },
}
</script>

<style lang="scss">
</style>
