import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loader: true, // 加载状态
  },
  mutations: {
    /**
     * 更新状态
     */
    updateLoader (state, val) {
      state.loader = val ? true : false
    },
  },
  actions: {
  },
  modules: {
  }
})
