import VueCookies from 'vue-cookies'
export default {
  // 节流阀
  throttle (callBack, time) {
    let timer = null
    return function() {
      if (!timer) {
        timer = setTimeout(() => {
          callBack()
          timer = null
        }, time)
      }
    }
  },
  // 时间格式化
  timeFormat (value) {
    if (value) {
      let add0 = (m) => {
        return m < 10 ? '0' + m : m
      }
      var time = new Date(value)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var mm = time.getMinutes()
      var s = time.getSeconds()
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
    } else {
      return ''
    }
  },
  // 日期格式化
  dateFormat (value) {
    if (value) {
      let add0 = (m) => {
        return m < 10 ? '0' + m : m
      }
      var time = new Date(value)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      return y + '-' + add0(m) + '-' + add0(d)
    } else {
      return ''
    }
  },
  // 手机号验证
  isPhone (phone) {
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
      return false;
    }
    return true
  },
  // 邮箱验证
  isEmail (e) {
    return /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(e)
  },
  // 身份证验证
  isIdCard (str) {
    if(!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str))){ 
      return false;
    }
    return true
  },
  // 判断微信
  isWeixin () {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  },
  // 百度坐标转换高德坐标
  bd_decrypt: function (bd_lng, bd_lat) {
    var X_PI = Math.PI * 3000.0 / 180.0;
    var x = bd_lng - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
    var gg_lng = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    return {lng: gg_lng, lat: gg_lat}
  },
  // 获取缓存
  getCache (key) {
    if(VueCookies.get(key)){
      try {
        return JSON.parse(VueCookies.get(key))
      }
      catch(err) {
        return VueCookies.get(key)
      }
    }else if(window.localStorage.getItem(key)){
      try {
        return JSON.parse(window.localStorage.getItem(key))
      }
      catch(err) {
        return window.localStorage.getItem(key)
      }
    }else{
      return ''
    }
  },
  // 设置缓存
  setCache (key, val) {
    try {
      window.localStorage.setItem(key, JSON.stringify(val))
    }
    catch(err) {
      window.localStorage.setItem(key, val)
    }
    try {
      VueCookies.set(key, JSON.stringify(val))
    }
    catch(err) {
      VueCookies.set(key, val)
    }
  },
  // 删除key
  removeCache (key) {
    window.localStorage.removeItem(key)
    VueCookies.remove(key)
  },
  // 32位 uuid
  uuid () {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "";
  
    var uuid = s.join("");
    return uuid;
  },
}