import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: '个人中心',
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/User.vue'),
    meta: {
      title: '个人中心',
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/quv/home/:type',
    name: '视频拍摄-验机',
    component: () => import('@/views/quv/home.vue'),
    meta: {
      title: '验机',
      open: true,
      full: true,
    }
  },
  {
    path: '/quv/p1',
    name: '视频拍摄-参保手机',
    component: () => import('@/views/quv/p1.vue'),
    meta: {
      title: '为自己验机',
      open: true,
      full: true,
    }
  },
  {
    path: '/quv/s1',
    name: '视频拍摄-拍摄手机',
    component: () => import('@/views/quv/s1.vue'),
    meta: {
      title: '拍摄手机',
      open: true,
      full: true,
    }
  },
  {
    path: '/quv/p2',
    name: '视频拍摄-为他人验机',
    component: () => import('@/views/quv/p2.vue'),
    meta: {
      title: '为他人验机',
      open: true,
      full: true,
    }
  },
  {
    path: '/join/home',
    name: '商家入驻',
    component: () => import('@/views/join/Home.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/join/stores',
    name: '卖场入驻',
    component: () => import('@/views/join/Stores.vue'),
    meta: {
      title: '卖场入驻',
      open: true,
      full: true,
    }
  },
  {
    path: '/join/service',
    name: '服务站入驻',
    component: () => import('@/views/join/Service.vue'),
    meta: {
      title: '服务站入驻',
      open: true,
      full: true,
    }
  },
  {
    path: '/join/agent',
    name: '代理入驻',
    component: () => import('@/views/join/Agent.vue'),
    meta: {
      title: '代理入驻',
      open: true,
      full: true,
    }
  },
  {
    path: '/about',
    name: '关于我们',
    component: () => import('@/views/About.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/contact',
    name: '联系我们',
    component: () => import('@/views/Contact.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/query/400',
    name: '查询400',
    component: () => import('@/views/query/400.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/query/siteList',
    name: '查询维修站',
    component: () => import('@/views/query/SiteList.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/query/siteInfo/:id',
    name: '查询维修站',
    component: () => import('@/views/query/SiteInfo.vue'),
    meta: {
      open: true,
      full: true,
    }
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: '登录',
      open: true,
    }
  },
  {
    path: '/explainHide',
    name: '登录隐私条款',
    component: () => import('@/views/ExplainHide.vue'),
    meta: {
      title: '隐私条款',
      open: true,
    }
  },
  {
    path: '/explainAgreement',
    name: '用户使用协议',
    component: () => import('@/views/ExplainAgreement.vue'),
    meta: {
      title: '用户使用协议',
      open: true,
    }
  },
  {
    path: '/service',
    name: '服务',
    component: () => import('@/views/service/Index.vue'),
    meta: {
      title: '服务查询',
    }
  },
  {
    path: '/service/details/:id/:no',
    name: '服务详情',
    component: () => import('@/views/service/Details.vue'),
    meta: {
      title: '服务详情',
    }
  },
  {
    path: '/service/explain',
    name: '服务说明',
    component: () => import('@/views/service/Explain.vue'),
    meta: {
      title: '服务说明',
    }
  },
  {
    path: '/ask/form',
    name: '服务',
    component: () => import('@/views/ask/Form.vue'),
    meta: {
      title: '服务申请',
    }
  },
  {
    path: '/ask/success',
    name: '服务',
    component: () => import('@/views/ask/Success.vue'),
    meta: {
      title: '服务申请',
    }
  },
  {
    path: '/ask/auth',
    name: '服务',
    component: () => import('@/views/ask/Auth.vue'),
    meta: {
      title: '服务查看',
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
