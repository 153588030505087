var apis = {};
var appId = '';
if(process.env.VUE_APP_FLAG==="home"||process.env.VUE_APP_FLAG==undefined){
  // 开发环境和默认地址
  apis = {
    default: '//pp.shanquetech.com', // 接口地址
    link: '//ppf.shanquetech.com', // 前端地址
  };
  appId = 'wx53f5ad269a1c3308'; 
}else if(process.env.VUE_APP_FLAG==="dev"){
  // 测试环境
  apis = {
    default: '//pp.shanquetech.com',
    link: '//ppf.shanquetech.com',
  };
  appId = 'wx53f5ad269a1c3308'; 
}else if(process.env.VUE_APP_FLAG==="busi"){
  // 生产测试环境
  apis = {
    default: '//pp.trustinsure.cn',
    link: '//ppf.trustinsure.cn',
  };
  appId = 'wx8575bb128900cd9b'; 
}else if(process.env.VUE_APP_FLAG==="prod"){
  // 生产环境
  apis = {
    default: '//pp.trustinsure.cn',
    link: '//ppf.trustinsure.cn',
  };
  appId = 'wx8575bb128900cd9b'; 
}


export default {
  apis: apis,
  channelType: '5', // 渠道ID
  appId: appId,
  buyLink: 'https://weidian.com/item.html?itemID=4371662667', // 碎屏购买地址
  pass: {
    openId: '',
    phone: '',
    token: '',
    headimg: '', // 头像
    nickname: '', // 昵称
  },
  token: {
		'accessToken': '',
		'refreshToken': '',
	},
}