import Vue from 'vue'
import Cube from 'cube-ui'
import App from './App.vue'
import './assets/css/init.scss'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import init from './common/init'
import utils from './common/utils'
import BaiduMap from 'vue-baidu-map'
import 'vant/lib/index.css';
import { Collapse, CollapseItem } from 'vant';
Vue.use(Collapse);
Vue.use(CollapseItem);



// 地图
Vue.use(BaiduMap, {
  ak: 'fOn3rUwawXkafzqzXKGrtvS95enZvzeb'
})


Vue.use(Cube)
Vue.use(VueCookies)
Vue.prototype.$init = init
Vue.prototype.$utils = utils
Vue.prototype.$axios = axios


/**
 * 获取用户登录信息
 */
function getPower () {
  var pass = Vue.prototype.$utils.getCache('pass');
  if(pass.phone){
    Vue.prototype.$init.pass = pass;
  }
}
getPower();


/**
 * ajax封装
 */
function verificationCode (data) {
  var dataObj = data;
  try {
    dataObj = JSON.parse(data);
    if(dataObj.header.code == 'B1001'){
      router.push('/login')
      Cube.Toast.$create({
        txt: '请重新登录',
        type: 'warn'
      }).show()
    }
  } catch {
    if(dataObj.header.code == 'B1001'){
      router.push('/login')
      Cube.Toast.$create({
        txt: '请重新登录',
        type: 'warn'
      }).show()
    }
  }
  return dataObj;
}
Vue.prototype.$http = {
  post (url, data = {}) {
    return axios({
      method: 'post',
      baseURL: init.apis.default,
      url: url,
      data: data,
      timeout: 600000,
      headers: {
        'token': Vue.prototype.$init.pass.token
      },
      transformResponse: [ function (data) {
        return verificationCode(data);
      }],
    })
  },
  get (url, data = {}) {
    return axios({
      method: 'get',
      baseURL: init.apis.default,
      url: url,
      params: data,
      timeout: 600000,
      headers: {
        'token': Vue.prototype.$init.pass.token
      },
      transformResponse: [ function (data) {
        return verificationCode(data);
      }],
    })
  }
}


/**
 * 路径变化
 */
router.beforeEach((to, from, next) => {
  // title设置
  if (to.meta.title) {
    document.title = to.meta.title
  }else{
    document.title = '大诚保'
  }
  // 权限验证
  if (to.meta.open) {
    next()
  } else if (Vue.prototype.$init.pass.token) {
    next()
  } else {
    Vue.prototype.$utils.setCache('loginBack', to.fullPath)
    next('/login')
  }
  // 轮询处理
  if(window.ticketIn){
    clearInterval(window.ticketIn)
  }
})
router.afterEach(() => {
  // Vue.nextTick(() => {
    store.commit('updateLoader', false)
  // })
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
